<template>
  <EuiGridRow>
    <EuiGridColumn width="2/3">
      <EuiBlock :title="$t('paraphers.create.addDocuments.title')">
        <EuiFileUploader
          v-if="routeName.startsWith('paraphers.create')"
          :buttonText="$t('fileUploader.buttonText')"
          :separatorText="$t('fileUploader.separatorText')"
          :title="$t('fileUploader.title')"
          :subtitle="$t('fileUploader.subtitle')"
          multiple
          accept="application/pdf"
          @change:file-uploader="onFileUpload"
          @error:file-uploader="onError"/>
        <EuiTooltip v-show="Signbook.documents.length > 0 && selected.length > 0"
                    :content="$t('button.delete')" placement="top">
          <template slot="anchor">
            <EuiButton iconOnly color="primary" @click="removeSelectedDocuments()">
              <EuiIcon name="delete"/>
            </EuiButton>
          </template>
        </EuiTooltip>
        <ValidationProvider ref="provider" rules="required|templateDocs" v-slot="{ errors }">
          <p v-if="errors[0]"
             class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
            {{ $t('paraphers.create.addDocuments.none')}}
          </p>
          <EuiAdvancedTable v-model="Signbook.documents" :selectedValue.sync="selected">
            <EuiATableRow :selectable="routeName.startsWith('paraphers.create') ? 'multiple': undefined" autoSelect slot="tableRow" slot-scope="{ item, index }">
              <EuiATableCell :label="$t('settings.dialog.addUsers.table.name')">
                {{ item.name }}
                <template v-if="item.name === undefined">
                  <span class="eui-u-font-italic">
                      {{ $t('paraphers.create.addDocuments.selectDocument') }}
                  </span>
                </template>
              </EuiATableCell>
              <EuiATableCell v-if="fileTypes && fileTypes.length === 0">
              </EuiATableCell>
              <EuiATableCell label="Type" v-else-if="routeName.startsWith('paraphers.create') && fileTypes && fileTypes.length">
                <ValidationProvider name="type" :rules="Signbook.recipientOrganizations.length > 0 ? 'required' : ''" v-slot="{ errors }">
                <p v-for="(error, index) in errors" :key="index" class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
                  {{ error }}
                </p>
                <select class="eui-a-input -noIcon eui-u-px-2" @click.stop
                        :class="{'-invalid': false }"
                        v-model="item.type">
                  <option v-for="(option, index) in fileTypes" :value="option" :key="index + '-' + option.typeCode">
                    {{ option.typeDescription }}
                  </option>
                </select>
                </ValidationProvider>
              </EuiATableCell>
              <EuiATableCell v-else-if="item.type && item.type.typeDescription">
                {{ item.type.typeDescription }}
              </EuiATableCell>
              <EuiATableCell dataType="button">
                <EuiButton v-if="routeName.startsWith('paraphers.create')" iconOnly color="primary" @click.stop="removeDocument(item)">
                  <EuiIcon name="delete"/>
                </EuiButton>
                <EuiButton v-else variant="raised" color="primary" size="small"
                           @click.stop="selectedDocIndex = index; $refs.singleFileUploader.click()">
                  <template v-if="Signbook.documents[index].name">
                    {{ $t('button.change') }}
                  </template>
                  <template v-else>
                    {{ $t('button.upload') }}
                  </template>
                </EuiButton>
              </EuiATableCell>
            </EuiATableRow>
          </EuiAdvancedTable>
        </ValidationProvider>
      </EuiBlock>
    </EuiGridColumn>
    <input name="uploadSingle" ref="singleFileUploader" style="display: none" type="file" accept="application/pdf"
           @change="uploadSingleFile($event.target.files[0])">
  </EuiGridRow>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import mixin from '../../../mixins/compare';

export default {
  name: 'CreateParapherAddDocuments',
  inject: ['Signbook'],
  mixins: [mixin],
  data() {
    return {
      selected: [],
      selectedDocIndex: undefined,
      shouldUpdatePagePoint: false,
    };
  },
  watch: {
    'Signbook.documents'() {
      this.$nextTick(() => {
        return this.$refs.provider.validate();
      });
      /* const { valid } = await this.$refs.provider.validate(Signbook.documents);

      if (!valid) {
        console.log("not valid");
        return;
      } */
    },
  },
  computed: {
    ...mapState({
      fileTypes: (state) => state.ModuleEdocSign.fileTypes.all,
      routeName: (state) => state.route.name,
    }),
    ...mapGetters({
      can: 'ModuleEdocSign/auth/can',
    }),
  },
  methods: {
    ...mapActions({
      searchFileTypes: 'ModuleEdocSign/fileTypes/search',
    }),

    onFileUpload(files) {
      for (let i = 0; i < files.length; i += 1) {
        this.Signbook.documents.push({
          id: this.$uuid.v4(),
          name: files[i].name,
          fileContent: files[i],
          extension: 'pdf',
        });
      }
    },
    uploadSingleFile(file) {
      if (file) {
        const id = this.$uuid.v4();
        this.Signbook.signatories.forEach(item => {
          item.signatures.forEach(signature => {
            if (signature.zone.position.docIndex === this.selectedDocIndex) {
              signature.zone.position.docId = id;
            }
          });
          item.paraphs.forEach(paraph => {
            if (paraph.zone.position.docIndex === this.selectedDocIndex) {
              paraph.zone.position.docId = id;
            }
          });
          item.texts.forEach(text => {
            if (text.zone.position.docIndex === this.selectedDocIndex) {
              text.zone.position.docId = id;
            }
          });
        });
        this.$set(this.Signbook.documents[this.selectedDocIndex], 'id', id);
        this.$set(this.Signbook.documents[this.selectedDocIndex], 'name', file.name);
        this.$set(this.Signbook.documents[this.selectedDocIndex], 'fileContent', file);
        this.$set(this.Signbook.documents[this.selectedDocIndex], 'extension', 'pdf');
        this.Signbook.shouldUpdatePosition = true;
      }
    },
    onError(error) {
      console.log(error);
    },
    removeSelectedDocuments() {
      this.selected.forEach((document) => {
        this.Signbook.documents = this.Signbook.documents.filter((elem) => this.compare(elem, document) === false);
      });
      this.selected = [];
    },
    removeDocument(document) {
      if (document) {
        this.selected = this.selected.filter((obj) => obj.id !== document.id);
        this.Signbook.documents = this.Signbook.documents.filter((obj) => obj.id !== document.id);
        this.Signbook.signsList = this.Signbook.signsList.filter((obj) => obj.zone.position.docId !== document.id);
        this.Signbook.signatories.forEach(signatory => {
          const signatures = signatory.signatures ? signatory.signatures.filter(item => item.zone.position.docId !== document.id) : [];
          const paraphs = signatory.paraphs ? signatory.paraphs.filter(item => item.zone.position.docId !== document.id) : [];
          const texts = signatory.texts ? signatory.texts.filter(item => item.zone.position.docId !== document.id) : [];
          this.$set(signatory, 'signatures', signatures);
          this.$set(signatory, 'paraphs', paraphs);
          this.$set(signatory, 'texts', texts);
        });
      }
    },
  },
  mounted() {
    this.searchFileTypes({
      orders: [{
        fieldName: 'description', orderBy: 'ASC', offset: 0, limit: 100,
      }],
      params: [{
        'paramName': 'organizationId',
        'paramValue': this.Signbook.recipientOrganizations.length > 0 ? this.Signbook.recipientOrganizations[0].organizationId : '',
      }],
    });
  },
};
</script>
